// Migrated
<template lang="pug">
span.d-inline-block
  template(v-if="$isSol")
    component.review-star.d-inline-block(
      :is="currentComponent"
      v-if="stars === 0"
      :width="size"
      :height="size"
    )
    component.review-star.d-inline-block(
      :is="currentComponent"
      v-for="i in parseInt(6 - stars)"
      v-else-if="stars > 0",
      :key="`hss_${stars}_${i}`"
      width="16"
      height="16"
    )
  template(v-if="!$isSol")
    fa.star(
      v-if="stars === 0"
      icon="star"
      :style="{ width: `${size}px` }"
    )
    fa(
      v-for="i in parseInt(6 - stars)"
      v-else-if="stars > 0",
      :key="`hss_${stars}_${i}`",
      icon="star"
    )
</template>

<script>
export default defineNuxtComponent({
  name: 'StarByLocale',

  props: {
    dark: {
      type: Boolean,
      default: false,
    },

    size: {
      type: Number,
      default: 15,
    },

    stars: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    currentComponent () {
      return this.dark ? 'i-solresor-icon' : 'i-solresor-full'
    },
  },
})
</script>
